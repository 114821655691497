/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei/useGLTF";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modelli/maske.glb");

  const { tessuto } = props;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials["FABRIC 1_FRONT_3383"]}
        geometry={nodes.Pattern2D_10119_Node.geometry}
      >
        <meshStandardMaterial map={tessuto} roughness={1} toneMapped={false} />
      </mesh>
      <mesh
        material={materials["FABRIC 1_FRONT_3383"]}
        geometry={nodes.Pattern2D_10120_Node.geometry}
      >
        <meshStandardMaterial map={tessuto} roughness={1} toneMapped={false} />
      </mesh>
      <mesh
        material={materials["FABRIC 1_FRONT_3383"]}
        geometry={nodes.Pattern2D_112034_Node.geometry}
      >
        <meshStandardMaterial roughness={1} toneMapped={false} />
      </mesh>
      <mesh
        material={materials["FABRIC 1_FRONT_3383"]}
        geometry={nodes.Pattern2D_112035_Node.geometry}
      >
        <meshStandardMaterial roughness={1} toneMapped={false} />
      </mesh>
      <mesh
        material={materials["FABRIC 1_FRONT_3383"]}
        geometry={nodes.Pattern2D_1211229_Node.geometry}
      >
        <meshStandardMaterial map={tessuto} roughness={1} toneMapped={false} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/modelli/maske.glb");
