import React from "react"
import Card from "../../components/card"

const PrintCards = (props) => {
  
  const {cards, lingua, corrente} = props

  return(
  <div className="section">
    <div
      className="row columns is-multiline is-mobile"
      
    >
      {cards.map(({ node }) => (
        <div
          className="column is-half-mobile is-half-tablet is-half-desktop is-full-widescreen is-half-fullhd "
          key={node.data._id}
          
        >
          <Card tessuto={node} lingua={lingua} attivo={node.data.codice === corrente ? true : false} />
        </div>
      ))}
    </div>
  </div>
)}

export default PrintCards
