/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei/useGLTF";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modelli/etichetta.glb");

  const { label } = props;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials["FABRIC 1_FRONT_275721"]}
        geometry={nodes.Pattern2D_796424_Node.geometry}
        position={[0, 0.32, -0.123]}
        rotation={[0.3, 0, 0.02]}
      >
        <meshPhongMaterial map={label} roughness={0} toneMapped={false} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/modelli/etichetta.glb");
