/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/modelli/vela_collo_4.glb')

  const { tessuto, bottoni, asole, opa, matBottone, club } = props;

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 0.32, 0.02]} rotation={[1.75, 0.18, -0.08]}>
        <mesh
          material={materials['Material5126.001']}
          geometry={nodes.MatShape_12400_Node001.geometry}
          position={[0.039, 0.0225, 0.0357]}
          rotation={[-0.24, 0.18, -0.45]}
          scale={[0.41, 0.41, 0.41]}
          >
          <meshMatcapMaterial
            attach="material"
            matcap={matBottone}
            color={bottoni}
          />
        </mesh>
        <mesh
          material={materials['Material5126.001']}
          geometry={nodes.MatShape_12400_Node002.geometry}
          position={[-0.052, 0.015, 0.021]}
          rotation={[0.24, -0.22, 0.45]}
          scale={[0.41, 0.41, 0.41]}
          >
          <meshMatcapMaterial
            attach="material"
            matcap={matBottone}
            color={bottoni}
          />
        </mesh>
      </group>
      <mesh
        material={materials['FABRIC 1_FRONT_13988']}
        geometry={nodes.Collar_8_Node.geometry}
        position={[-0.0015, 0.35, -0.04]}
      >
        <meshStandardMaterial
          map={!club ? tessuto : null}
          roughness={1}
          toneMapped={false}
        />
      </mesh>
      <mesh
        material={materials['PT_FABRIC_FRONT_1815.006']}
        geometry={nodes.MatShape_11510_Node.geometry}
        position={[-0.045, 0.29, 0.031]}
        rotation={[-0.17, 0.26, 1.46]}
        scale={[0.784, 0.995, 0.656]}
        >
        <meshStandardMaterial color={asole} transparent={true} opacity={opa} />
      </mesh>
      <mesh
        material={materials['PT_FABRIC_FRONT_1815.006']}
        geometry={nodes.MatShape_11510_Node001.geometry}
        position={[0.045, 0.29, 0.031]}
        rotation={[-0.85, 1.37, 2.32]}
        scale={[0.784, 0.995, 0.656]}
        >
        <meshStandardMaterial color={asole} transparent={true} opacity={opa} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/modelli/vela_collo_4.glb')
